.launch-app {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.launch-app-cont {
    width: 100%;
    display: flex;
    justify-content: center;
}

.launch-app-cont-1 {
    width: 100%;
    padding: 0 25px;
    margin-bottom: 50px;
    margin-top: 30px;
}

.launch1-sub-text {
 text-align: justify;
}


/* ---------------------------------------BOX-------------------------------------------- */

.row {
    width: 100%;
}

.roadmap-area {
    overflow: hidden;
  }

  .roadmap-title {
    display: block;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 45px;
    margin-left: 14px;
    letter-spacing: 0.56px;
    text-transform: uppercase;
  }

  .box {
    border: 1px solid #030627;
    background: #030627;
    border-radius: 15px;
    padding: 25px 25px 25px;
    min-height: 261.43px;
    margin-bottom: 25px;
  }

  .launch-app .box {
    border: 1px solid #efefef;
    background: #030627;
    box-shadow: 0px 0px 10px 0px #030627;
}

  .box .title {
    display: flex;
    align-items: flex-start;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 32px;
    gap: 16px;
  }

  .box .title .dot {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: block;
    position: relative;
    background: rgba(255 255 255 / 11%);
    flex: 0 0 auto;
    transform: translateY(3px);
  }

  .box .title .dot::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: linear-gradient(0deg, var(--tg-primary-color) 0%, var(--tg-green) 47.60%, var(--tg-black) 98.23%)
  }

  .box .title .dot::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 24px;
    width: 1px;
    height: 120px;
    background: #292A37;
  }

  .box p {
    margin-bottom: 0;
  }

  .roadMap-active .col-lg-4 {
    padding: 0 15px;
  }


.inner_total_text {
    display: flex;
    justify-content: center;
}

.box-head {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.box-button {
    font-size: 16px;
    border-radius: 10px;
    /* background-image: linear-gradient(40deg, #252525, #3503be);
    box-shadow: 0px 0px 15px 0px #3503be;
    border: #b4b4b4 solid 2.5px; */
    height: 30px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}


progress[value] {
    width: 80%;
    max-height: 10px;
    background-color: rgba(0, 0, 255, 0);
}

progress[value]::-webkit-progress-bar {
    background-color: rgba(255, 255, 255, 0.808);
    border-radius: 45px;
    overflow: hidden;
}

progress[value]::-webkit-progress-value {
    background-image: linear-gradient(40deg, #252525, #3503be);
}

.progress-bar {
    background-color: transparent;
    text-align: left;
}

.progress-bar-cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.allocation {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;
}

/* ---------------------------------------------Tabs------------------------------------------------- */

.tabs {
    margin-top: 15vh;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 5;
}

.tabs-cont {
    width: 85%;
    max-width: 85%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.tab {
    margin: 15px;
    min-width: 200px;
    min-height: 60px;
    box-shadow: none;
    border: 2px solid #FFE;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    border-radius: 30px;
    font-size: 15px;
    cursor: pointer;
    flex-wrap: wrap;
}

.change-size {
    color: #010314;
    background-color: #fff;
}

@media(max-width:800px) {


    .tab {
        margin: 10px;
        border-radius: 20px;
        font-size: 16px;
    }
}

@media(min-width:1500px) {

    .launch-app-cont-1 {
        width: 100%;
        max-width: 1380px;
        padding: 0 25px;
    }

}

