.counterClass {
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 25px;
}

.counterBtn {
    font-weight: 600;
    background-color: #4f43b6;
    width: 35px;
    height: 35px;
    /* text-align: center; */
    border-radius: 5px;
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    margin-right: 20px;
    cursor: pointer;
}

.counterAmount {
    font-size: 20px;
    margin-right: 20px;
}