*{
  box-sizing: border-box;
  padding: 0%;
  margin: 0%;
}

::-webkit-scrollbar{
  display: none;
}

body{
  background-color: #140e38;
  font-family: 'Comfortaa', cursive;
}

.head-text{
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  color: white;
  position: relative;
  z-index: 1;
}

.shade-text{
  position: absolute;
  color: #ffffff13;
  left: 50%;
  top:-80%;
  transform: translateX(-50%);
  font-size: 5rem;
  z-index: -1;
  font-weight: bold;
}

.para{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 68px;
  font-weight: 600;
  line-height: 1.9rem;
  color: white;
}

.para-cont{
  max-width:50%;
  text-align:center;
}

.button-style{
  background-image: linear-gradient(40deg, #f959ff, #b700ff);
  border-radius: 5px;
  box-shadow: 0px 0px 15px 0px rgba(247, 0, 255, 0.75);
  border:none;
  font-family: 'Comfortaa', cursive;
  font-size: 18px;
  font-weight: bold;
  color:white;
  padding: 1rem;
}

.button-style:hover{
  background-image: linear-gradient(50deg, #fc4cfc, #e100ff);
  cursor: pointer;
}

.heading-1{
  font-size: 32px;
}

.heading-2{
  font-size: 24px;
}

.heading-3{
  font-size: 18px;
}

.heading-4{
  font-size: 14px;
}

@media (max-width:700px){

  .para{
    margin-top: 40px;
  }

.para-cont{
    min-width: 100%;
  }

  .button-style{
    font-size: 14px;
    padding: 1rem 0.8rem;
  }

}
