@import "./assets/css/animate.min.css";
@import "./assets/css/bootstrap.min.css";
@import "./assets/css/default.css";
@import "./assets/css/fontawesome-all.min.css";
@import "./assets/css/magnific-popup.min.css";
@import "./assets/css/responsive.css";
@import "./assets/css/slick.css";
@import "./assets/css/style.css";


.App{
  /* background-color: rgba(100, 148, 237, 0); */
  background-color: #010314;
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  /* background-image: linear-gradient(60deg , #140E38 0%, #140E38 25%, #14387B 35%, #14387B 65%, #140E38 75%, #140E38 100%); */
  background-repeat: no-repeat;
}

.particles-cont{
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
    overflow: hidden;
    max-width: 100vw;
    max-height: 100vh;
    z-index: 0;
}

.react-sweet-progress-symbol {
  color: white !important;
  font-weight: 400;
}