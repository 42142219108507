.launch1-box-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.mat-table {
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(1.5px);
    -webkit-backdrop-filter: blur(2.5px);
}

.launch1-box {
    width: 40%;
    border: #b4b4b4 solid 5px;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(1.5px);
    -webkit-backdrop-filter: blur(2.5px);
    padding: 1rem;
    margin: 10px;
    color: white;
    border-radius: 20px;
}

.launch1-box .transactionHash {
    width: 95% !important;
    margin: 10px auto 0px auto !important;
}

.launch1-box .transactionHash .hash-box {
    width: 100%;
    text-align: left;
    box-shadow: 0px 0px 5px 0px #a191a1 !important;
    border-radius: 5px !important;
    padding: 5px;
}

.launch1-box .transactionHash .hash-box .cstmText {
    font-size: 12px;
    letter-spacing: .5px;
}

.launch1-box .transactionHash .hash-box a {
    overflow-wrap: break-word;
    color: white;
    text-decoration: none;
}

.launch1-box-website-btn {
    font-size: 16px;
    border-radius: 8px;
    background-image: linear-gradient(40deg, #252525, #3503be);
    box-shadow: 0px 0px 15px 0px rgba(76, 0, 255, 0.75);
    border: #b4b4b4 solid 2.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin-top: 30px;
    padding: 0.5rem;
    cursor: pointer;
}

.launch1-box-website-btn a {
    text-decoration: none;
    color: white;
}

.launch1-box-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* position: absolute;
    bottom: 20px;
    left: 0px; */
    width: 100%;
    margin-top: 30px;
}

.launch1-box-button .cstmBtn {
    color: white;
    background-image: linear-gradient(40deg, #252525, #3503be);
    box-shadow: 0 0 15px 0 rgb(76 0 255 / 75%);
    border: 2.5px solid #b4b4b4;
    padding: 10px 10px;
    border-radius: 10px;
    width: 92%;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    cursor: pointer;
}

.launch1-box-button .tokenPrice {
    position: absolute;
    top: -20px;
    font-size: 14px;
    color: wheat;
    letter-spacing: 0.2px;
    font-weight: 500;
}

.launch1-box-button .cstmBtn.maxBtn {
    position: absolute;
    right: 0px;
    width: 16%;
    top: 0px;
    font-size: 15px;
    padding: 7px;
    background-color: #9c19a2;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 1px #4d0770;
}

.launch1-box-button .cstmInput {
    width: 100%;
    border: none;
    padding: 0px 0px;
    border-radius: 0;
    font-size: 18px;
    font-weight: 600;
    background-color: transparent;
    color: white;
    border-bottom: 1px solid white;
    letter-spacing: .3px;
    margin-bottom: 5px;
    height: 40px;
}

.launch1-box-button .cstmInput:focus {
    outline: none;
    box-shadow: none;
}

.launch1-box-button .cstmInput::placeholder {
    color: rgb(231, 229, 229);
}


.launch1-sub-head {
    font-size: 16px;
    color: #e2e2e2;
}

.launch1-sub-text {
    font-size: 16px;
    color: #e2e2e2;
    margin-top: 40px;
}

.launch1-mini-box {
    display: flex;
    padding: 1rem;
    border-top: 1px solid white;
}

.launch1-mini-1 {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.table-cont {
    position: relative;
    z-index: 5;
    margin-top: 60px;
    padding: 1rem;


}

.table-button {
    padding: 0.6rem 1.2rem;
    border: none;
    background-image: linear-gradient(40deg, #252525, #3503be);
    border-radius: 8px;
    cursor: pointer;
    color: white;
}

.isDisabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}

.claim-text {
    color: wheat;
    background-color: transparent;
    padding: 15px;
    text-align: center;
    letter-spacing: .5px;
}

@media (max-width:800px) {
    .launch1-box {
        width: 100%;
    }
}