.navbar {
    display: flex;
    position: absolute;
    width: 100%;
    max-width: 100vw;
    justify-content: center;
    top: 0%;
    left: 0%;
    padding: 30px 15px 15px!important;
    transition: 0.5s;
    z-index: 10;

}

.navbar-cont {
    width: 100%;
    max-height: none;
    background-color: #140e3800;
    color: white;
    display: flex;
    justify-content: space-between;
}

.navbar-left {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 160px;
}

.navbar-left #logo {
    max-width: 150px;
    width: 100%;

}

.navbar-right {
    display: flex;
    width: calc(100% - 160px);
    justify-content: space-between;
}

.menu-item {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 800;
    padding: 0 20px;
}

.menu-link {
    color: white;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 800;
}

.menu-link:visited {
    color: white;
}

.menu-link:hover {
    color: rgb(216, 216, 216);
}


.menu-item .networkName {
    color: wheat;
    text-transform: uppercase;
    margin-left: 5px;
}

/* ----------------------------------------------Small Menu---------------------------------------------------------- */


.bar-menu {
    display: none;
}

.bars {
    height: 2px;
    background-color: white;
    width: 30px;
    border-radius: 5px;
}

.small-nav {
    display: none;
    position: fixed;
    transition: 1s;
}

.small-nav-ul {
    width: 85%;
    background-color: #010314;
    list-style: none;
    text-align: center;
    color: white;
    max-height: 0px;
    overflow: hidden;
    transition: 1s;
    padding-left: 0px;
}

.small-nav-li {
    padding: 0.5rem 0rem;
}


/* -----------------------------------------------Hover Menu--------------------------------------------------------- */


.hover-menu {
    background-color: white;
    position: fixed;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    border-radius: 40px;
    z-index: 10;
}

.hover-menu-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.6rem;
    border-radius: 40px;
}

.hover-item {
    margin: 14px 0px;
    color: rgb(56, 56, 56);
    cursor: pointer;
}

.hover-item:hover {
    color: rgb(0, 0, 0);
    transform: scale3d(1.2, 1.2, 1.2);
}
.mainoldmenu {
    display: flex;
    justify-content: center;
    width: 100%;
}

.moreButtons {
    display: flex;
}

.moreButtons a {
    font-size: 14px;
    display: flex;
    align-items: center;
    background: var(--tg-white);
    color: var(--tg-black);
    font-weight: 800;
    gap: 12px;
    padding: 14px 23px;
    border-radius: 30px;
    height: 49px;
    margin-left: 15px;
    white-space: nowrap;
}

.moreButtons a:first-child {
    margin-left: 0px;
}

.moreButtons a:hover{
    background: linear-gradient(82.3deg, #df86aa 10.8%, #5729d6 94.3%);
    transition: all .475s;
    color: #fff;
}
/* -----------------------------------------------Media Queries------------------------------------------------------ */


@media (max-width:1000px) {

    .navbar {
        padding: 1rem 0.5rem;
        position: absolute;
    }

    #logo {
        max-width: 50%;
    }

    .menu-item {
        display: none;
    }

    .bar-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        border: none;
        border-radius: 5px;
        padding: 3px;
        cursor: pointer;
        margin-left: 20px;
    }

    .small-nav {
        display: flex;
        width: 100%;
        background-color: #010314;
        justify-content: center;
        position: relative;
        z-index: 1;
    }

    .hover-menu {
        display: none;
    }


}

@media (min-width:1500px) {
    /* .navbar-cont {
        width: 65%;
        max-width: 65%;
    } */

    #logo {
        max-width: 100%;
    }
}